<template>
    <v-card :class="service_tv && service_inet_billing ? 'mb-0' : 'mb-4'" outlined elevation="0">
      <!-- Toolbar Телебачення або ПП --> 
      <v-toolbar
        :class="$vuetify.theme.dark ? '' : 'lighten-2'"
        :color="service_tv ? 'accent' : 'grey '"
        dense
        flat
      >
      <!--<div class="custom-border-left primary"></div>-->
        <v-toolbar-title v-if="abonent"><h3 class="mr-6 secondary--text">{{ checkDoubleTarif ? 'ТБ + Інтернет' : 'Телебачення' }}</h3></v-toolbar-title> 
        
        <v-spacer />
        
        <template v-if="user && user.user_type != 2">
          <v-btn
            v-if="!service_tv || service_tv.status == null"
            title="Додати послугу ТБ"
            color="secondary"
            icon
            @click="$parent.$refs.addService.show('add_tv', 'tv')" 
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          
          <v-menu v-else buttom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn 
                v-on="on"
                color="secondary"
                title="Опції"
                icon
              >
                <v-icon >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="$parent.$refs.addService.show('change_tv', 'tv')">
                <v-icon class="pr-3">mdi-pencil</v-icon>
                <v-list-item-title>Редагувати сервіс ТБ</v-list-item-title> 
              </v-list-item>
              <v-list-item v-if="service_tv && service_inet_billing && checkBalance" 
                @click="$parent.$refs.confirmTransferBalance.show(false, service_inet_billing.operator)"
              >
                <v-icon class="pr-3">mdi-swap-horizontal</v-icon>
                <v-list-item-title>Перенос залишків</v-list-item-title> 
              </v-list-item>
              <v-list-item v-if="service_tv.change_tarif.length > 0" @click="$parent.$refs.confirmCancelPlanTarif.show(service_tv.change_tarif[0])">
                <v-icon class="pr-3">mdi-cancel</v-icon>
                <v-list-item-title>Скасувати заплановані тарифи</v-list-item-title> 
              </v-list-item>
              <v-list-item 
              @click="$parent.$refs.addCredit.show('tv')"
              >
                <v-icon class="pr-3">mdi-credit-card-plus-outline</v-icon>
                <v-list-item-title>Додати кредит</v-list-item-title>
              </v-list-item>
              <!-- Тимчасово прибираємо тимчасову активацію
              <template v-if="checkTempActivate">
                <v-divider />
                <v-list-item 
                @click="actionTempActiveService()"
                >
                  <v-icon class="pr-3">mdi-timer-check-outline</v-icon>
                  <v-list-item-title>Активувати ( тимчасово )</v-list-item-title>
                </v-list-item>
              </template>
              -->
            </v-list>
          </v-menu>
        </template>
        <template v-else> <!-- для монтера-->
          <v-btn
            color="secondary"
            class=""
            icon
            title="Додати кредит"
            @click="$parent.$refs.addCredit.show('tv')"
          >
            <v-icon>mdi-credit-card-plus-outline</v-icon>
          </v-btn>
      </template>
        
        <v-btn
            v-if="service_tv && $vuetify.breakpoint.smAndDown"
            color="secondary"
            icon
            @click="showCardTV = !showCardTV"
        >
            <v-icon>{{ showCardTV && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
        </v-btn>
      </v-toolbar>
      <!-------------------------------->
      <!-- Блок помилок при ПП -->
      <template v-if="checkDoubleTarif">
        <v-alert
          v-if="checkMinusBalanceTv"
          rounded="0"
          class="my-0 mb-1"
          color="red  lighten-1"
          dense
          type="warning"
          icon="mdi-television" 
        >
          Недостатньо коштів на балансі ТБ
        </v-alert>
        <v-alert
          v-if="checkMinusBalanceInet"
          rounded="0"
          class="my-0 mb-1"
          color="red  lighten-1"
          dense
          type="warning"
          icon="mdi-web"
        >
          Недостатньо коштів на балансі Інтернет
        </v-alert>
      </template>
      <!------------------------->
      <!-- Блок списку полів -->
      <v-list dense v-if="service_tv && showCardTV">
        <!-- Поле: Статус -->
        <template >
          <v-list-item>
            <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
              <v-list-item-title >Статус:</v-list-item-title>
            </v-list-item-content> 
            <v-list-item-content>
              <StatusService :service_tv="service_tv" :checkDoubleTarif="checkDoubleTarif" :service_inet_billing="service_inet_billing" />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>
        <!------------------>

        <!-- Поле: Кредит -->
        <template v-if="service_tv.balance_additional.credit && service_tv.balance_additional.credit != '0.00'">
          <v-list-item>
            <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
              <v-list-item-title >Кредит:
                <v-icon :title="`Надано до: ${service_tv.sheduled_credit.date_due}`" v-if="service_tv.sheduled_credit" style="vertical-align: sub;" class="ml-2" color="orange">mdi-clock-outline</v-icon>
              </v-list-item-title>
            </v-list-item-content> 
            <v-list-item-content>
              <span>
                <v-chip @click="$parent.$refs.addCredit.show('tv')" small  class="white--text" color="orange" style="vertical-align: baseline;">
                  <v-icon small color="" left>mdi-credit-card-outline</v-icon>
                  {{ service_tv.balance_additional.credit }} грн.
                </v-chip>
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>      
        <!------------------>

        <!-- Згенерований Список полів statusTvItems -->
        <template v-for="(item, index) in statusTvItems" >
          <v-divider v-if="index != 0" :key="item.title + 1"></v-divider>
          
          <v-list-item :key="item.title" >
            <!-- Кастомні Заголовки полів --> 
            <v-list-item-content style="max-width: 150px;">
               
              <v-list-item-title >
                <template v-if="item.name == 'balance'">
                  <span v-if="service_tv.balance > 0">Переплата:</span>
                  <span v-else>Сума до сплати:</span>
                </template>
                <template v-else-if="item.name == 'tarif' && service_tv.change_tarif.length > 0">
                  Тариф:
                  <!-- Tooltip: Деталі запланованого тарифу -->
                  <DetailTarif :is_nextMonth="true" :tarif=service_tv.change_tarif[0].next_tarif :tarifNextData="service_tv.change_tarif[0]" />
                  <!----------------------->
                </template>
                <template v-else>{{ item.title }}</template>
              </v-list-item-title>
            </v-list-item-content>
            <!------------------------------>
            <!-- Кастомні значення полів -->
            <v-list-item-content>
            <template v-if="item.name == 'status'">
                <span class="">
                  <v-chip small text-color="white" :color="service_tv[item.name] ? 'success': 'red' " >{{ service_tv[item.name] && "Підключений" || "Відключений" }}</v-chip>
                </span>
            </template>
            
            <template v-else-if="item.name == 'balance'">
              <span class="d-flex justify-space-between align-center"> 
                
                <span>
                <!-- Значення балансу -->
                  <v-tooltip bottom color="secondary darken-1" content-class='custom-tooltip'>
                    <template v-slot:activator="{ on }">
                      <span style="cursor:pointer" v-on="on">
                        <span :class="{'error--text': checkMinusBalanceTv}">{{ service_tv[item.name] }} грн.</span>
                        
                      </span>
                    </template>
                    <!-- Тіло Tooltip: Усі баланси -->
                    <span>
                      <strong>{{ checkDoubleTarif ? 'ТБ + ІНТЕРНЕТ' :'ТЕЛЕБАЧЕННЯ' }}</strong><br>
                      <template v-for="(item, index) in balanceList">
                        <div :key="index" class="d-flex justify-space-between">
                          <span class="mr-auto" :class="item.bold ? 'font-weight-bold' : ''">{{ item.title }}</span> 
                          <span class="ml-4 text-end" :class="{ 'deep-orange--text' : negativeBalance(item.item),  'font-weight-bold' : item.bold }">{{ service_tv.balance_additional && service_tv.balance_additional[item.item] }}</span>
                          <br>
                        </div>
                      </template>
                    </span>
                    <!------------------------------->
                  </v-tooltip>
                  <!---------------------->
                  
                </span>
                <!-- Список активованих опцій cardItemsChips (Новаком,...) --> 
                <span>
                    <v-chip 
                      v-if="service_tv && service_tv.novakom && novakomData[service_tv.novakom]"
                      :title="novakomData[service_tv.novakom].name"
                      :color="novakomData[service_tv.novakom].color"
                      class="pa-1 ml-1"
                      outlined
                      small
                      pill
                    >
                      {{ novakomData[service_tv.novakom].title }}
                    </v-chip>
                  
                </span>
                <!--------------------------------------------->
              </span>
            </template>

            <template v-else-if="item.name == 'tarif'">
              <span class="d-flex justify-space-between align-center">
                <!-- Tooltip: Деталі поточного тарифу -->
                <DetailTarif :tarif="service_tv && service_tv.tarif" />
                <!-------------------------------------->
                <v-btn
                  title="Змінити Тариф"
                  color="primary"
                  small
                  icon
                  @click="checkPlanTarif()"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </span>
            </template>
            
            <template v-else-if="item.name == 'date_contract'">
              <span color="primary lighten-1" :title="`№ Договору: ${ service_tv.contract && service_tv.contract }`">{{ service_tv[item.name] }}</span>
            </template>

            <template v-else-if="item.name == 'operator'">
                {{ service_tv && operators && operators.find(x => x.id == service_tv[item.name]).name}}
                </template>
            <template v-else>
                {{ service_tv[item.name] }}
                <!--<v-list-item-title v-text="item.value"></v-list-item-title>-->
            </template>
            </v-list-item-content>
            <!----------------------------->
          </v-list-item>
        </template>
        <!------------------------------->
      </v-list>
      <!------------------>
      <v-card-actions v-if="cardItemsChips.find(item => service_tv && service_tv[item.name]) && showCardTV && false">
        <template v-for="(item, index) in cardItemsChips">
          <v-chip
            v-if="service_tv && service_tv[item.name]"
            :key="index"
            :color="item.color"
            class="ma-2"
            outlined
            small
            pill
          >
            {{ item.title }}
          </v-chip>
      </template>
      </v-card-actions>
    </v-card> 
</template>

<script>
  import axios from "axios"
  import DetailTarif from '@/components/AbonentInfo/TabInfoCards/DetailTarif.vue'
  import StatusService from '@/components/AbonentInfo/TabInfoCards/StatusService.vue'
  export default {
    props: ["user", "abonent", "rates", "service_tv", "operators", "service_inet_billing", "checkDoubleTarif", "connectData"],
    components: {
      DetailTarif,
      StatusService
    },
    data: () => ({
      showCardTV: true,
      novakomData: {0: {name: "Не передавати дані", title: ""}, 1: {name: "Нульові значення передаються у Нова-Ком", title: "НK", color: "blue"}, 2: {name: "Баланс (стан рахунку) абонента передається у Нова-Ком", title: "НБ", color: "red"}, 3: {name: "Баланс (стан рахунку) абонента передається у Нова-Ком без переплати", title: "НП", color: "green"}},
      cardItemsChips: [ 
        {title: "НK", discript: 'Данні абонента передаються у Нова-Ком',     name: "novakom", color: "blue"},
        {title: "НБ", discript: 'Баланс (стан рахунку) абонента передається у Нова-Ком',     name: "balance_novakom", color: "red"},
      ],
      statusTvItems: [
        //{ title: 'Статус:',        name: "status", color: "primary"},
        { title: 'Сума до сплати:',        name: 'balance' },
        { title: 'Тариф:',         name: "tarif" }, 
        //{ title: '№ Договору:',    name: "contract" },
        { title: 'Дата договору:', name: "date_contract" },
        {title: 'Особовий рахунок:',  name: "user_id" },
        {title: "Постачальник:",       name: "operator", type: "text"},
      ],
      balanceList: [
        { title: "Вартість тарифу:  ", item: "tarif_cost" },
        { title: "", item: "" },
        { title: "Сума на початок місяця:  ", item: "balance_start_month" },
        { title: "Сума Абонплати:  ", item: "monthly_fee" },
        { title: "Сума Послуг:  ", item: "monthly_services" },
        { title: "Платежі за місяць:  ", item: "monthly_pays" },
        { title: "", item: "" },
        { title: "Поточний баланс:  ", item: "current_balance", bold: true },
        { title: "Кредит: ", item:  "credit" },
        { title: "Знижка: ", item:  "discount" },
        { title: "Борг:  ", item: "debt" },
        { title: "", item: "" },
        { title: "Сума до сплати з урахуванням", item: "" },
        { title: "наступного місяця:", item: "amount_to_pay" },
      ],
      
      balanceListInet: [
        //
      ],
    }),
    computed:{
      checkBalance(){
        return this.service_tv?.balance && Number(this.service_tv.balance) > 0 || false
      },
      checkMinusBalanceInet(){
        let current = this.service_inet_billing.balance_additional.current_balance && Number(this.service_inet_billing.balance_additional.current_balance) || 0
        let credit = this.service_inet_billing.balance_additional.credit && Number(this.service_inet_billing.balance_additional.credit) || 0
        return (current + credit) < 0
      },
      checkMinusBalanceTv(){
        let current = this.service_tv.balance_additional.current_balance && Number(this.service_tv.balance_additional.current_balance) || 0
        let credit = this.service_tv.balance_additional.credit && Number(this.service_tv.balance_additional.credit) || 0
        return (current + credit) < 0
      },
      checkTempActivate(){
        return this.checkDoubleTarif && !this.service_inet_billing?.status && !this.service_tv?.temporary_activate || false
      }
    },
    watch:{
      /*service_tv(){
        let is_prepaid_operator = this.operators.find(x => x.id == this.service_tv?.operator)?.is_prepaid
        if(is_prepaid_operator) this.balanceList = this.balanceList_prepaid 
        else this.balanceList = this.balanceList_no_prepaid
      }*/
    },
    methods:{
      goToTab(){
        this.$parent.$parent.toTab(1)
      },
      negativeBalance(balance){
        return balance && this.service_tv?.balance_additional[balance].indexOf('-') != -1 || false
      },
      getDataTarif(tarif_id, item){
        this.rates.find(x => x.id == tarif_id)?.[item]
      },
      async checkPlanTarif(){
        console.log("bsdbdfsbdfnfsd")
        let question = await this.$parent.$refs.confirmInfoForPlanChangeTarif.open("tv")
        if(question) {
          if(this.checkDoubleTarif) this.$parent.$refs.addRatesDouble.show('change') 
          else this.$parent.$refs.addRates.show('change')
        }
      },
      async actionTempActiveService(){
        let question = await this.$parent.$refs.confirmDialog.open('Активувати послугу?', "Ви впевнені, що хочете активувати послугу для абонента на дві доби?")
        if(question) {
          axios({
              method: "post",
              url: '/api/cabletv/get_user/temp_activate/',
              data: { user_service: this.service_tv.id}
          })
            .then(response => {
            if(response){
              this.$emit('update-abonent')
              this.$router.app.$snack.show("Послугу тимчасово активовано!")
              
            }else{
                this.$router.app.$snack.show("Помилка", 'deep-orange')
            }
            })
            .catch(err => {
            //console.log(err);
                this.$router.app.$sheet.show("Помилка!", err);
            })
        }
      }
    }
  }
</script>

<style scoped>
  .custom-border-left{
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    

    /*opacity: .26;*/
    
    top: 0;

    left: 0;
    right: 0;

    border-style: solid;
    border-width: 2px;
    content: "";
    position: absolute;
  }
  
</style>